var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(context){return [_c('vx-card',{staticClass:"mb-base"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit(context)}}},[_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ชื่อ Highlights")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"ชื่อ Highlights","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"title","placeholder":"กรอกชื่อ Highlights"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("รายละเอียด Highlights")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"รายละเอียด Highlights","rules":"required|max:300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-textarea',{staticClass:"w-full",class:{ 'border border-danger': errors.length > 0 },attrs:{"rows":6,"label":"รายละเอียด Highlights"},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("เลือกประเภทสื่อ")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('FormContent',[_c('ul',{staticClass:"flex space-x-2"},[_c('li',[_c('vs-radio',{attrs:{"vs-name":"radios1","vs-value":"lead_image"},model:{value:(_vm.formData.media_type),callback:function ($$v) {_vm.$set(_vm.formData, "media_type", $$v)},expression:"formData.media_type"}},[_vm._v("Lead Image")])],1),_c('li',[_c('vs-radio',{attrs:{"vs-name":"radios1","vs-value":"video_youtube"},model:{value:(_vm.formData.media_type),callback:function ($$v) {_vm.$set(_vm.formData, "media_type", $$v)},expression:"formData.media_type"}},[_vm._v("VDO Youtube")])],1),_c('li',[_c('vs-radio',{attrs:{"vs-name":"radios1","vs-value":"video_uploaded"},model:{value:(_vm.formData.media_type),callback:function ($$v) {_vm.$set(_vm.formData, "media_type", $$v)},expression:"formData.media_type"}},[_vm._v("VDO File")])],1)])])],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("\n              "+_vm._s(_vm.labelImage)+"\n            ")]),_c('br'),_c('span',[_vm._v("\n              (รูปขนาด 1920 x 1080 )\n            ")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"Thumbnail","rules":"required|lh_mimes:jpg,png,gif|lh_image_resolution:1920,1080"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ImageUpload',{attrs:{"folder":_vm.ImageUploadFolder,"selection-name":"image","has-delete-button":""},model:{value:(_vm.formData.media_content.image),callback:function ($$v) {_vm.$set(_vm.formData.media_content, "image", $$v)},expression:"formData.media_content.image"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.media_type === 'video_youtube'),expression:"formData.media_type === 'video_youtube'"}]},[_c('FormLabel',[_c('span',[_vm._v("URL Youtube")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"rules":("" + (_vm.formData.media_type === 'video_youtube' ? 'required|url' : '')),"name":"URL Youtube"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"placeholder":"URL Youtube","name":"asset_value"},model:{value:(_vm.formData.media_content.video_url),callback:function ($$v) {_vm.$set(_vm.formData.media_content, "video_url", $$v)},expression:"formData.media_content.video_url"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.media_type === 'video_uploaded'),expression:"formData.media_type === 'video_uploaded'"}]},[_c('FormLabel',[_c('span',[_vm._v("Video (เฉพาะไฟล์ .mp4)")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")]),_c('br'),_c('span',{staticClass:"font-bold"},[_vm._v("ขนาดไฟล์ vdo ไม่เกิน 300 MB")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"rules":("" + (_vm.formData.media_type === 'video_uploaded' ? 'required|lh_size:300_MB' : 'lh_size:300_MB')),"name":"Video"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('VdoUpload',{attrs:{"folder":(_vm.baseFolder + "/highlight/" + (_vm.formData.folder_name) + "/VDO"),"selection-name":"assets","has-delete-button":""},model:{value:(_vm.formData.media_content.video_file),callback:function ($$v) {_vm.$set(_vm.formData.media_content, "video_file", $$v)},expression:"formData.media_content.video_file"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('DatepickerRangeForm',{attrs:{"required":""},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("Link URL")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"Link URL","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"link_url"},model:{value:(_vm.formData.link_url),callback:function ($$v) {_vm.$set(_vm.formData, "link_url", $$v)},expression:"formData.link_url"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('vs-divider'),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แก้ไขล่าสุดโดย")])]),_c('div',{staticClass:"flex items-center vx-col w-full md:w-6/12"},[_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.formData.updated_by))])])],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แก้ไขล่าสุดวันที่")])]),_c('div',{staticClass:"flex items-center vx-col w-full md:w-6/12"},[_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.formData.updated_at)))])])],1),_c('FormLayout',{staticClass:"mt-8"},[_c('FormLabel'),_c('FormContent',[_c('vs-button',{staticClass:"mr-2",on:{"click":function($event){return _vm.submit(context)}}},[_vm._v("\n              "+_vm._s(_vm.formData.id ? 'Update': 'Submit')+"               \n            ")])],1)],1)],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }