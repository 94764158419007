<template>
  <div>
    <ValidationObserver v-slot="context">
      <vx-card class="mb-base">
        <form @submit.prevent="submit(context)">
          <FormLayout>
            <FormLabel>
              <span>ชื่อ Highlights</span> <span class="text-danger"> *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="ชื่อ Highlights"
                rules="required"
              >
                <vs-input
                  v-model="formData.title"
                  class="w-full"
                  name="title"
                  placeholder="กรอกชื่อ Highlights"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>รายละเอียด Highlights</span>
              <span class="text-danger"> *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="รายละเอียด Highlights"
                rules="required|max:300"
              >
                <vs-textarea
                  :class="{ 'border border-danger': errors.length > 0 }"
                  v-model="formData.description"
                  :rows="6"
                  label="รายละเอียด Highlights"
                  class="w-full"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>เลือกประเภทสื่อ</span> <span class="text-danger"> *</span>
            </FormLabel>
            <FormContent>
              <ul class="flex space-x-2">
                <li>
                  <vs-radio
                    v-model="formData.media_type"
                    vs-name="radios1"
                    vs-value="lead_image"
                  >Lead Image</vs-radio
                  >
                </li>
                <li>
                  <vs-radio
                    v-model="formData.media_type"
                    vs-name="radios1"
                    vs-value="video_youtube"
                  >VDO Youtube</vs-radio
                  >
                </li>
                <li>
                  <vs-radio
                    v-model="formData.media_type"
                    vs-name="radios1"
                    vs-value="video_uploaded"
                  >VDO File</vs-radio
                  >
                </li>
              </ul>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span
              >
                {{ labelImage }}
              </span>
              <br />
              <span>
                (รูปขนาด 1920 x 1080 )
              </span>
              <span class="text-danger"> *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="Thumbnail"
                rules="required|lh_mimes:jpg,png,gif|lh_image_resolution:1920,1080"
              >
                <ImageUpload
                  :folder="ImageUploadFolder"
                  v-model="formData.media_content.image"
                  selection-name="image"
                  has-delete-button
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout v-show="formData.media_type === 'video_youtube'">
            <FormLabel>
              <span>URL Youtube</span> <span class="text-danger"> *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                :rules="`${
                  formData.media_type === 'video_youtube' ? 'required|url' : ''
                }`"
                name="URL Youtube"
              >
                <vs-input
                  v-model="formData.media_content.video_url"
                  class="w-full"
                  placeholder="URL Youtube"
                  name="asset_value"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout v-show="formData.media_type === 'video_uploaded'">
            <FormLabel>
              <span>Video (เฉพาะไฟล์ .mp4)</span> <span class="text-danger"> *</span>
              <br><span class="font-bold">ขนาดไฟล์ vdo ไม่เกิน 300 MB</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                :rules="`${formData.media_type === 'video_uploaded' ? 'required|lh_size:300_MB' : 'lh_size:300_MB'}`"
                name="Video"
              >
                <VdoUpload
                  :folder="`${baseFolder}/highlight/${formData.folder_name}/VDO`"
                  v-model="formData.media_content.video_file"
                  selection-name="assets"
                  has-delete-button
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <DatepickerRangeForm
            v-model="dateRange"
            required
          />
          <FormLayout>
            <FormLabel>
              <span>Link URL</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="Link URL" 
                rules="url">
                <vs-input 
                  v-model="formData.link_url" 
                  class="w-full" 
                  name="link_url" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <vs-divider />
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดโดย</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formData.updated_by}}</span>
            </div>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดวันที่</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
            </div>
          </FormLayout>
          <FormLayout class="mt-8">
            <FormLabel />
            <FormContent>
              <vs-button 
                class="mr-2" 
                @click="submit(context)"
              >
                {{ formData.id ? 'Update': 'Submit' }}               
              </vs-button>  
            </FormContent>
          </FormLayout>
        </form>
      </vx-card>
    </ValidationObserver>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { computed, onMounted, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { quillEditor } from 'vue-quill-editor'
import ImageUpload from '@/components/upload/ImageUpload'
import VdoUpload from '@/components/upload/VdoUpload'
import useCrud from '@/use/useCrud'
import useBannerHighlightUtil from '@/use/useBannerHighlightUtil'
import DatepickerRangeForm from '@/components/DatepickerRangeForm'
import env from '@/env'
import omit from 'lodash/omit'
import { insertBr } from '@/helper/Utils'

export default {
  name: 'BannerHighlightForm',
  components: {
    DatepickerRangeForm,
    VdoUpload,
    ImageUpload,
    ValidationObserver,
    QuillEditor: quillEditor,
  },
  setup(props, ctx) {
    const { formData, dateRange, fetchByRouteId } = useBannerHighlightUtil(ctx)
    const crudForm = useCrud(ctx, 'bannerHighlight')

    crudForm.routePrefix.value = 'highlight'

    onMounted(() => {
      fetchByRouteId()
    })

    watch(
      () => dateRange,
      (newValue) => {
        formData.start_date = newValue.start_date
        formData.end_date = newValue.end_date
      },
      {
        deep: true,
      },
    )

    const cloudinaryBaseFolder = env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER
    const baseFolder = computed(() => cloudinaryBaseFolder)

    const ImageUploadFolder = computed(() => {
      if (formData.media_type === 'video_uploaded') {
        return `${cloudinaryBaseFolder}/highlight/${formData.folder_name}/VDO Thumbnail`
      }

      if (formData.media_type === 'video_youtube') {
        return `${cloudinaryBaseFolder}/highlight/${formData.folder_name}/VDO Thumbnail`
      }

      return `${cloudinaryBaseFolder}/highlight/${formData.folder_name}/Image`
    })

    const labelImage = computed(() => {
      if (formData.media_type === 'video_uploaded') {
        return 'Thumbnail VDO'
      }

      if (formData.media_type === 'video_youtube') {
        return 'Thumbnail youtube'
      }

      return 'Lead Image'
    })

    const submit = (ctx) => {
      // replace new line with br
      formData.description = insertBr(formData.description)
      crudForm.addItem(ctx, omit(formData, 'updated_at'))
    }

    return {
      ...crudForm,
      dateRange,
      formData,
      labelImage,
      ImageUploadFolder,
      submit,
      baseFolder
    }
  },
}
</script>

<style scoped>
.content-editor {
  height: 22rem;
}
.editor {
  height: 15rem;
}
</style>
