<template>
  <FormLayout>
    <FormLabel>
      <span>ระบุช่วงเวลา</span>
      <span v-if="required" class="text-danger"> *</span>
    </FormLabel>
    <FormContent>
      <div class="flex flex-col md:flex-row md:-mx-2">
        <div class="w-full mb-2 md:w-1/3 md:px-2">
          <ValidationProvider
            v-slot="{ errors }"
            :rules="validateRules"
            name="ช่วงเวลาเริ่มต้น"
          >
            <Datepicker
              v-model="$attrs.value.start_date"
              :highlighted="{
                dates: [
                  // Highlight an array of dates
                  $attrs.value.start_date
                    ? new Date($attrs.value.start_date)
                    : new Date(),
                ],
              }"
              :disabled-dates="$attrs.value.end_date ? {
                from: new Date($attrs.value.end_date)
              } : {}"
              format="dd/MM/yyyy"
              placeholder="ช่วงเวลาเริ่มต้น"
            />
            <span 
              v-if="errors.length > 0" 
              class="text-danger text-sm">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </div>
        <div class="w-full md:w-1/3 md:px-2">
          <ValidationProvider
            v-slot="{ errors }"
            :rules="validateRules"
            name="ช่วงเวลาสิ้นสุด"
          >
            <Datepicker
              v-model="$attrs.value.end_date"
              :highlighted="{
                dates: [
                  // Highlight an array of dates
                  $attrs.value.end_date ? new Date($attrs.value.end_date) : new Date(),
                ],
              }"
              :disabled-dates="$attrs.value.start_date ? {
                to: new Date($attrs.value.start_date)
              } : {}"
              format="dd/MM/yyyy"
              placeholder="ช่วงเวลาสิ้นสุด"
            />
            <span 
              v-if="errors.length > 0" 
              class="text-danger text-sm">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </div>
      </div>
    </FormContent>
  </FormLayout>
</template>

<script>
/* eslint-disable */
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import useDatepicker from '@/use/useDatepicker'
import { computed, watch } from '@vue/composition-api'
export default {
  name: 'DatepickerRangeForm',
  components: { Datepicker },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const validateRules = computed(() => {
      return props.required ? 'required' : ''
    })

    return {
      useDatepicker: useDatepicker(ctx),
      validateRules,
    }
  },
}
</script>
